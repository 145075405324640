import { css } from '@linaria/core';

import { COLOR } from '~/src/styles/color';

css`
  :global() {
    a {
      color: ${COLOR.primary};
      text-decoration-color: transparent;
      text-decoration-thickness: 2.5px;
      text-underline-offset: 2.5px;
      transition: text-decoration-color 0.25s ease;
      font-weight: 600;
      &:hover {
        text-decoration-color: ${COLOR.primary};
      }
    }
  }
`;
