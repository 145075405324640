import { styled } from '@linaria/react';
import { Link } from 'gatsby';

import { LAYOUT } from '~/src/styles/layout';
import { COLOR, inLightTheme } from '~/src/styles/color';
import { FONT_SCALE } from '~/src/styles/font';

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem ${LAYOUT.pageGutterY};
  height: 100px;

  background: linear-gradient(${COLOR['background.lower']}, transparent);
`;

const HeaderLeft = styled.div`
  flex-grow: 1;
`;

const HeaderRight = styled.div`
  flex-grow: 0;

  display: flex;
`;

const NavigationMenu = styled.ul`
  display: flex;
  align-items: stretch;
  gap: 20px;
  padding: 0;
  margin: 0;

  list-style: none;
`;

const NavigationMenuItem = styled.li`
  display: block;
  letter-spacing: .1rem;
  font-size: ${FONT_SCALE.S};
`;

// @ts-ignore
const NavigationMenuLink = (styled(Link)`
  padding: 20px 0;

  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  font-style: normal;

  color: ${COLOR.text};
  &.active {
    color: ${COLOR.primary};
    text-decoration-color: ${COLOR.primary};
  }
  :hover {
    color: ${COLOR.primary};
  }
  transition: color 0.2s, text-decoration-color 0.2s;
`) as typeof Link<unknown>;

// @ts-ignore
const TitleLink = (styled(Link)`
  color: ${COLOR.text};
  text-decoration: none;
  user-select: none;

  font-size: 24px;
  letter-spacing: 0.4rem;
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
  padding-top: 10px;
  padding-bottom: 10px;

  ${inLightTheme(
  {
    'font-weight': '600',
    ':hover': {
      color: 'transparent',
      transition: 'color 0.4s 0.3s',
    },
  })}

  :before {
    z-index: 1;
    content: 'ianchen.dev';
    position: absolute;
    color: transparent;

    background: text linear-gradient(
      130deg,
      #faffa1 5%,
      #02f5e9 20%,
      #d1f090 70%,
      #FF7B72 90%
    );
      ${inLightTheme(
    {
      background: `
      text linear-gradient(
        130deg,
        #FF7B72 0%,
        #6945D4 25%,
        #008b6d 67%,
        #07419e 95%
      );
      `,
      'font-weight': '600',
    })}
    pointer-events: none;
  }

  /*
    If 'hover' is supported, enable the animation.
    Otherwise, since 'clip-path' and 'opacity' are not set,
    It will render the colorful version.
  */
  @media (hover: hover) {
    :before {
      transition: clip-path 0.3s, opacity 0.3s;
      clip-path: polygon(50% 0, 50% 100%, 50% 100%, 50% 0);
      opacity: 0;
    }
    :hover:before {
      clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
      opacity:1;
    }
  }
`) as typeof Link<unknown>;

const DefaultHeader = () => {
  return (
    <Header>
      <HeaderLeft>
        <TitleLink to="/">ianchen.dev</TitleLink>
      </HeaderLeft>

      <HeaderRight role="navigation">
        <NavigationMenu>
          <NavigationMenuItem>
            <NavigationMenuLink to="/about" activeClassName="active">About</NavigationMenuLink>
          </NavigationMenuItem>
        </NavigationMenu>
      </HeaderRight>
    </Header>
  );
};

export default DefaultHeader;
