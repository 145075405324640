import { styled } from '@linaria/react';
import { LAYOUT } from '~/src/styles/layout';

const Footer = styled.footer`
  padding: 0 ${LAYOUT.pageGutterY};

  display: flex;
  justify-content: center;

  margin: 1rem 2rem;

  letter-spacing: .1rem;
  font-family: Urbanist, sans-serif;
  font-size: .75rem;
`;

const DefaultFooter = () => {
  return (
    <Footer>Ian Chen 2024</Footer>
  );
};

export default DefaultFooter;
