import { styled } from '@linaria/react';

import { LAYOUT, SPACE } from '~/src/styles/layout';

const Main = styled.main`
  margin-bottom: calc(5 * ${SPACE.VerticalRhythm});
  padding: 0 ${LAYOUT.pageGutterY};
`;

const DefaultMain = ({ children }) => {
  return (
    <Main>{children}</Main>
  );
};

export default DefaultMain;
