/* eslint-disable max-len */

import { css } from '@linaria/core';

import { FONT_SCALE, FONT_SCALE_KEY, FONT_WEIGHT } from '~/src/styles/font';
import { SPACE, SPACE_KEY } from '~/src/styles/layout';

css`
:global() {
  @font-face {
    font-family: 'Source Sans 3';
    font-style: normal;
    font-weight: 200 900;
    font-display: swap;
    src: url(/fonts/SourceSans3.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: Urbanist;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url(/fonts/Urbanist.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  

  ${{
    ':root': {
      /* eslint-disable max-len */
      '--font-sans': '"Source Sans 3",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
      '--font-mono': '"Roboto Mono",Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace',
      /* eslint-enable max-len */

      [SPACE_KEY.VerticalRhythm]: '1.6875rem',

      /* font sizes */
      [FONT_SCALE_KEY.S]: '0.75rem', // 12px
      [FONT_SCALE_KEY.M]: '1.1875rem', // 19px
      [FONT_SCALE_KEY.L]: '1.5rem', // 24px
      [FONT_SCALE_KEY.XL]: '1.875rem', // 30px
      [FONT_SCALE_KEY.XXL]: '2.25rem', // 36px
      [FONT_SCALE_KEY.XXXL]: '3rem', // 48px
    },
  }}

  body {
    font-family: var(--font-sans);
    font-size: ${FONT_SCALE.M};
    line-height: ${SPACE.VerticalRhythm};
    font-weight: ${FONT_WEIGHT.normal};
    letter-spacing: 0.0125rem;
  }

  h1 {
    font-size: ${FONT_SCALE.XXXL};
    line-height: calc(2 * ${SPACE.VerticalRhythm});
    margin-top: calc(2 * ${SPACE.VerticalRhythm});
    margin-bottom: calc(2 * ${SPACE.VerticalRhythm});
    font-weight: ${FONT_WEIGHT.title};
  }

  h2 {
    font-size: ${FONT_SCALE.XXL};
    line-height: calc(2 * ${SPACE.VerticalRhythm});
    margin-top: ${SPACE.VerticalRhythm};
    margin-bottom: ${SPACE.VerticalRhythm};
    font-weight: ${FONT_WEIGHT.bold};
    letter-spacing: 0.1rem;
  }

  h3 {
    font-size: ${FONT_SCALE.XL};
    line-height: calc(2 * ${SPACE.VerticalRhythm});
    margin-top: calc(2 * ${SPACE.VerticalRhythm});
    margin-bottom: ${SPACE.VerticalRhythm};
    font-weight: ${FONT_WEIGHT.bold};
    letter-spacing: 0.1rem;
  }

  h4 {
    font-size: ${FONT_SCALE.L};
    line-height: ${SPACE.VerticalRhythm};
    margin-top: ${SPACE.VerticalRhythm};
    margin-bottom: ${SPACE.VerticalRhythm};
    font-weight: ${FONT_WEIGHT.bold};
    letter-spacing: 0.03125rem;
  }

  h5 {
    font-size: ${FONT_SCALE.M};
    line-height: ${SPACE.VerticalRhythm};
    margin-top: ${SPACE.VerticalRhythm};
    margin-bottom: ${SPACE.VerticalRhythm};
    font-weight: ${FONT_WEIGHT.bold};
    letter-spacing: 0.03125rem;
  }

  p, ul {
    margin-top: ${SPACE.VerticalRhythm};
    margin-bottom: ${SPACE.VerticalRhythm};
  }

  hr {
    width: 15px;
    margin-top: calc(1.5 * ${SPACE.VerticalRhythm});
    margin-bottom: calc(.15 * ${SPACE.VerticalRhythm});
  }
}
`;
