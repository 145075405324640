
import { GatsbyBrowser } from 'gatsby';

import './src/globals/color';
import './src/globals/font';
import './src/globals/layout';
import './src/globals/link';

import { isNoLayout } from './src/layout/layoutUtils';
import DefaultLayout from './src/layout/DefaultLayout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const noLayout = isNoLayout(props);

  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return noLayout
    ? element
    : (
      <DefaultLayout {...props}>
        {element}
      </DefaultLayout>
    );
};
