// These color definitions should be placed before other
// CSS snippets.
//
// The class names `dark-theme` and `light-theme` are intented to be placed
// on the root element (<html/>). The rules for applying themes
//   1. If html has the style `.theme-dark` or `.theme-light, apply light theme
//   2. If the user agent supports `prefers-color-scheme`, use the value
//   3. Use the dark theme.
//
// The implementation has 2 tricks to minimize duplicated definitions:
//   1. Increase Specificity by duplicating the class name.
//   2. Omit `@media (prefers-color-scheme: dark)`

import { css } from '@linaria/core';

import { COLOR_KEY, COLOR, inLightTheme, inDarkTheme } from '~/src/styles/color';

const themeDark = {
  [COLOR_KEY.grey900]: '#F2F7F7',
  [COLOR_KEY.grey800]: '#E1E4E4',
  [COLOR_KEY.grey700]: '#C9CDCD',
  [COLOR_KEY.grey600]: '#ADB1B1',
  [COLOR_KEY.grey500]: '#909494',
  [COLOR_KEY.grey400]: '#747777',
  [COLOR_KEY.grey300]: '#595C5C',
  [COLOR_KEY.grey200]: '#404343',
  [COLOR_KEY.grey100]: '#282A2A',

  [COLOR_KEY.text]: '#e3e3e3',
  [COLOR_KEY['text.selection']]: '#141920',
  [COLOR_KEY.primary]: '#00ded3',
  [COLOR_KEY.background]: '#141920',
  [COLOR_KEY['background.lower']]: '#0b0e12',
  'color-scheme': 'light',
};

const themeLight = {
  [COLOR_KEY.grey900]: '#181A1A',
  [COLOR_KEY.grey800]: '#343636',
  [COLOR_KEY.grey700]: '#4D4F4F',
  [COLOR_KEY.grey600]: '#676A6A',
  [COLOR_KEY.grey500]: '#828585',
  [COLOR_KEY.grey400]: '#9FA2A2',
  [COLOR_KEY.grey300]: '#BCC0C0',
  [COLOR_KEY.grey200]: '#DADEDE',
  [COLOR_KEY.grey100]: '#F2F6F6',

  [COLOR_KEY.text]: '#181A1A',
  [COLOR_KEY['text.selection']]: '#FAFAFA',
  [COLOR_KEY.primary]: '#008270',
  [COLOR_KEY.background]: '#FAFAFA',
  [COLOR_KEY['background.lower']]: '#e9e9e9',
  'color-scheme': 'light',
};

/*
                        data-theme
                          x  D  L
  prefers-color-scheme  D D  D  L
                        L L  D  L
*/

export const globals = css`
:global() {

  ${inDarkTheme(themeDark)}
  ${inLightTheme(themeLight)}

  body {
    background-color: ${COLOR['background.lower']};
    color: ${COLOR.text};
    transition: color 350ms ease 0s, background 350ms ease 0s;
  }

  ::selection {
    background-color: ${COLOR.primary};
    color: ${COLOR['text.selection']};
  }

  hr {
    color: ${COLOR.text};
  }
}
`;
