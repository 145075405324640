import { styled } from '@linaria/react';
import { PropsWithChildren } from 'react';
import { PageProps } from 'gatsby';

import { COLOR } from '../styles/color';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import DefaultMain from './DefaultMain';

const DefaultRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  min-height: 100vh;
  min-width: 320px;
  overflow: hidden;
  background-color: ${COLOR.background};
`;

const DefaultLayout = ({ children }: PropsWithChildren<Omit<PageProps, 'children'>>) => {
  return (
    <DefaultRoot>
      <DefaultHeader />
      <DefaultMain>
        {children}
      </DefaultMain>
      <DefaultFooter/>
    </DefaultRoot>
  );
};

export default DefaultLayout;
