import { css } from '@linaria/core';

import { SPACE_KEY } from '~/src/styles/layout';
import { COLOR } from '../styles/color';

export const globals = css`
:global() {
  ${{
    ':root': {
      '--layout-page-gutter-y': 'max(calc(40vw - 300px), 20px)',
      '--layout-page-width': 'max(min(calc(100vw - 40px), calc(20vw + 600px)), 0)',

      [SPACE_KEY.S]: '0.75rem', // 12px
      [SPACE_KEY.M]: '1rem', // 16px
      [SPACE_KEY.L]: '1.25rem', // 20px
      [SPACE_KEY.XL]: '1.75rem', // 28px
      [SPACE_KEY.XXL]: '2.25rem', // 36px
      [SPACE_KEY.XXXL]: '3rem', // 48px,
    },
  }}

  html {
    box-sizing: border-box;
    
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  #root {
    display: flex;
    
    flex-direction: column;

    margin: auto;
    min-height: 100vh;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  blockquote {
    border-left: 2px solid ${COLOR.primary};
    margin-left: -1px;
    margin-right: 0px;
    padding-left: 20px;
  }
}
`;

